import React from "react";
import { makeStyles, CssBaseline, Grid } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { IoIosArrowForward } from "react-icons/io";
import { FaExclamation } from "react-icons/fa";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    firstCennik: {
        backgroundImage: `url(/photos/nowy-cennik.jpg)`,
        height: "800px",
        padding: "24px",
        width: "50%",
        margin: "0px 10px",
        backgroundSize: "100% 100%",
        imageRendering: "-webkit-optimize-contrast",
        [theme.breakpoints.down("md")]: {
            backgroundImage: `url(/photos/nowy-cennik.jpg)`,
            height: "500px",
            backgroundSize: "100% 100%",
            padding: "24px",
            margin: "0px auto",
            width: "88%",
            imageRendering: "-webkit-optimize-contrast",
        },
    },
    secondCennik: {
        backgroundImage: `url(/photos/nowy-cennik2.jpg)`,
        height: "800px",
        padding: "24px",
        width: "50%",
        backgroundSize: "100% 100%",
        imageRendering: "-webkit-optimize-contrast",
        [theme.breakpoints.down("md")]: {
            backgroundImage: `url(/photos/nowy-cennik2.jpg)`,
            height: "500px",
            backgroundSize: "100% 100%",
            padding: "24px",
            width: "88%",
            margin: "0px auto",
            imageRendering: "-webkit-optimize-contrast",
        },
    },
    thirdCennik: {
        backgroundImage: `url(/photos/cennik.png)`,
        height: "800px",
        backgroundSize: "100% 100%",
        padding: "24px",
        imageRendering: "-webkit-optimize-contrast",
    },
    container: {
        [theme.breakpoints.up("md")]: {
            border: "1px solid black",
            padding: "24px",
            margin: "0 auto",
            width: "80%",
            display: "flex",
        },
    },
    eachMultisport1: {
        height: "100px",
        width: "100%",
        backgroundImage: `url(/photos/multisport1.png)`,
        imageRendering: "-webkit-optimize-contrast",

        backgroundSize: "100% 100%",
        [theme.breakpoints.down("md")]: {
            height: "100px",
        },
    },
    eachMultisport2: {
        height: "300px",
        backgroundImage: `url(/photos/multisport2.jpg)`,
        imageRendering: "-webkit-optimize-contrast",

        backgroundSize: "100% 100%",
    },
    eachMultisport3: {
        height: "300px",
        backgroundImage: `url(/photos/multisport3.jpg)`,
        imageRendering: "-webkit-optimize-contrast",

        backgroundSize: "100% 100%",
    },
    eachMultisport4: {
        height: "400px",
        backgroundImage: `url(/photos/multisport4.jpg)`,
        imageRendering: "-webkit-optimize-contrast",

        backgroundSize: "100% 100%",
        [theme.breakpoints.down("md")]: {
            height: "300px",
        },
    },
    dotPay: {
        alignItems: "bottom",
        height: "200px",
        backgroundImage: `url(/photos/dotpay.jpg)`,
        imageRendering: "-webkit-optimize-contrast",

        backgroundSize: "100% 100%",
        [theme.breakpoints.down("md")]: {
            height: "100px",
        },
    },
    eachMultisport5: {
        height: "400px",
        backgroundImage: `url(/photos/multisport5.png)`,
        imageRendering: "-webkit-optimize-contrast",
        backgroundSize: "100% 100%",
    },
    dottedList: {
        listStyleType: "none",
        paddingLeft: 0,
    },
    listItem: {
        display: "list-item",
        listStyleType: "disc",
        listStylePosition: "inside",
    },
}));
const Cennik = () => {
    const classes = useStyles();
    const lol = "xD";
    return (
        <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={12}>
                <div className={classes.container}>
                    <div className={classes.firstCennik} />
                    <div className={classes.secondCennik} />
                </div>
                <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                    <FaExclamation />{" "}
                    <span>
                        Pamiętaj! Jeśli jesteś studentem lub uczniem przysługuje
                        Ci zniżka!
                    </span>{" "}
                    <FaExclamation />
                </p>
                <p>
                    Wystarczy, abyś zabrał ze sobą ważną legitymację i okazał ją
                    w recepcji squasha! Zniżka przysługuje do 26 roku życia.
                    Wystarczy, aby jedna z osób wynajmujących kort posiadała
                    legitymację, aby otrzymać zniżkę na kort! Możesz skorzystać
                    z rabatu codziennie od 9:00 do 15:00 i zapłacić za kort
                    jedynie 35zł!
                </p>
                <p style={{ fontWeight: "bold" }}>
                    Nie masz rakiety lub okularów ochronnych!? To nie problem!
                    Oferujemy możliwość wypożyczenia akcesoriów do gry w
                    squasha!.
                </p>
                <div>
                    <ul className={classes.dottedList}>
                        <li className={classes.listItem}>
                            Koszt wynajmu rakiety: 1szt - 10zł/1h
                        </li>
                        <li className={classes.listItem}>
                            Koszt wynajmu okularów: 1szt - 5zł/1h
                        </li>
                    </ul>
                    <p>
                        <FaExclamation />
                        <span>Uwaga</span>
                        <FaExclamation />
                        Pamiętaj, że na jednym korcie (60 minut) przysługuje Ci
                        możliwość skorzystania z maksymalnie dwóch zniżek - 2
                        kart.
                    </p>
                    <p>
                        Chcesz nauczyć się gry w squasha!? Umów się na grę z
                        naszymi trenerami!
                    </p>
                    <p>
                        Godzinny trening squasha to koszt od 80 zł. W zależności
                        od wyboru trenera i wykupionego pakietu. Pamiętaj, że
                        wykupując 10 treningów, cena jest niższa.
                    </p>
                    <p>
                        Godzinny trening dla dzieci i młodzieży to koszt 60 zł.
                        Jeśli zakupisz pakiet 10 treningów, łączny koszt wynosi
                        500 zł.
                    </p>
                    <p>
                        Do ceny treningu należy doliczyć koszt wynajmu kortu do
                        gry w squasha według cennika.
                    </p>
                    <p>
                        Zadzwoń i umów się na trening lub rezerwuj online{" "}
                        <Link
                            className={classes.linkStyle}
                            to="/squash-rzeszow-rezerwacje">
                            tutaj
                        </Link>
                    </p>
                    <p>
                        Jan Rochecki - <b>604-595-729</b>
                    </p>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} />
            <Grid style={{ textAlign: "center" }} item xs={12} sm={12} md={12}>
                <h3 style={{ color: "#333333" }}>Honorujemy karty</h3>
            </Grid>
            <Grid
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                item
                xs={12}
                sm={12}
                md={4}>
                <div className={classes.eachMultisport1} />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <div className={classes.eachMultisport2} />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <div className={classes.eachMultisport3} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <div className={classes.eachMultisport4} />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                md={6}
                style={{
                    display: "flex",
                    alignItems: "stretch",
                    flexDirection: "column-reverse",
                }}>
                <div className={classes.dotPay} />
            </Grid>
        </Grid>
    );
};

export default Cennik;
